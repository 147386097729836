import { ReactElement, useEffect, useState } from 'react';
import cx from 'classnames';
import type { ApiNavbarNavbar, ButtonsButton } from '@eva/cms-types';

import { getStringData, getUploadFileUrl } from '../../lib/strapi';

interface Props {
  data: ApiNavbarNavbar['attributes'];
}

interface SectionProps {
  href?: string;
  text?: string;
}

function SectionAnchor(props: SectionProps): ReactElement {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const isAnchor = props.href?.includes('#');

    if (!isAnchor) {
      return;
    }

    const section = document.getElementById(
      props.href?.split('#')?.at(-1) ?? '',
    );

    if (!section) {
      return;
    }

    const observer = new window.IntersectionObserver(
      ([entry]) => {
        setIsActive(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      },
    );

    observer.observe(section);

    return () => observer.unobserve(section);
  }, []);

  return (
    <a
      className={cx(
        'text-link min-h-[2.85rem] rounded-[0.625rem] transition-colors px-4 py-2 whitespace-nowrap text-white flex items-center text-center hover:bg-white-dark-rgba',
        {
          ['bg-white-dark-rgba']: isActive,
        },
      )}
      href={props.href}
    >
      {props.text}
    </a>
  );
}

function FloatingDesktopMenu(props: Props) {
  const [isVisible, setIsVisible] = useState(false);

  const { data } = props;

  const { logoUrl: logoHref } = getStringData(data);
  const { url: logoUrl, alt: logoAlt } = getUploadFileUrl(data.logo);

  useEffect(() => {
    const navbar = document.getElementById('eva-nav');

    if (!navbar) {
      return;
    }

    const observer = new window.IntersectionObserver(
      ([entry]) => {
        setIsVisible(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      },
    );

    observer.observe(navbar);

    return () => observer.unobserve(navbar);
  }, []);

  const lastAction = (
    data.action as unknown as ButtonsButton['attributes'][]
  ).at(-1);

  return (
    <div className="hidden desktop:block">
      <nav
        className={cx(
          'z-50 p-2 fixed flex gap-4 opacity-0 bottom-10 transition-opacity rounded-2xl backdrop-blur bg-dark-rgba min-h-[2.85rem] mx-auto w-fit left-0 right-0',
          {
            ['opacity-100']: isVisible,
          },
        )}
      >
        <a href={logoHref ?? '/'} className="flex items-center text-center">
          <img
            src={logoUrl}
            alt={logoAlt}
            loading="lazy"
            decoding="async"
            className="w-[6.5rem] h-[1.5rem] justify-self-start"
          />
        </a>
        <div className="flex">
          {(data.navigation as unknown as ButtonsButton['attributes'][]).map(
            (item, index) => {
              const { href, text } = getStringData(item);
              return <SectionAnchor key={index} href={href} text={text} />;
            },
          )}
        </div>
        {lastAction && (
          <a
            href={lastAction.href.toString()}
            className="transition-colors duration-300 min-h-[2.85rem] flex items-center p-3 rounded-2xl text-cta text-center bg-white text-dark hover:bg-dark hover:text-white"
          >
            {lastAction.text.toString()}
          </a>
        )}
      </nav>
    </div>
  );
}

export default FloatingDesktopMenu;
